import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './CountdownTimer.css';  // Asegúrate de que el CSS está correctamente enlazado

const CountdownTimer = ({ targetDate }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      const timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
        minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
        seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0')
      };

      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="countdown-container">
      <div className="countdown-intro">
        <p>{t("¡Falta poco para el congreso!")}</p>  {/* Texto introductorio */}
      </div>
      <div className="countdown">
        <div className="time-unit">
          <span className="number">{timeLeft.days}</span>
          <span className="label">DÍAS</span>
        </div>
        <div className="time-unit">
          <span className="number">{timeLeft.hours}</span>
          <span className="label">HORAS</span>
        </div>
        <div className="time-unit">
          <span className="number">{timeLeft.minutes}</span>
          <span className="label">MINUTOS</span>
        </div>
        <div className="time-unit">
          <span className="number">{timeLeft.seconds}</span>
          <span className="label">SEGUNDOS</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
