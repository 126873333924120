export const localStorageKey = "cecSpace:app";

export const SLUGS = {
  HOME: "/",
  VENUE: "/venue",
  ABOUT: "/about",
  REGISTRATION_AND_SPONSORSHIP: "/registration-and-sponsorship",
  PROGRAM: "/program",
  TRAVEL: "/travel",
  MEDIA: "/media",
  CALLFORABSTRACTS: "/call-for-abstracts",
  PREVIOUS_EDITIONS: "/previous-editions",
  VENUE_CITY: "/venue-city",
  REGISTRATION: "/registration",
  SPEAKERS: "/speakers",
  HOTELS: "/hotels",
  SCHOLARSHIPS: "/scholarships",
  PRIVACY_POLICY: "/privacy-policy",
};
