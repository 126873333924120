import React from 'react';
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import './RegistrationStyles.css'; 

export const RegistrationContent = () => {
  const { t } = useTranslation();

  return (
    <div className="registration-content-container">
      <h2 className="registration-title">{t('REGISTRO CEC 2024')}</h2>
      <p className="registration-description text-center"
         dangerouslySetInnerHTML={{ __html: t('A continuación, se presentan las tarifas de inscripción para el Congreso Espacial Centroamericano 2024. <s>Aproveche los precios especiales de "Early Bird" inscribiéndose antes de la fecha límite.</s>') }}>
      </p>
      <div className="table-responsive">
        <table className="price-table">
          <thead>
            <tr>
              <th>{t('Categoría')}</th>
              <th><s>{t('Early Bird (USD)')}</s></th>
              <th>{t('Precio Normal (USD)')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('Participante completo (No estudiante)')}</td>
              <td><s>130 USD</s></td>
              <td>160 USD</td>
            </tr>
            <tr>
              <td>{t('Participante un día (No estudiante)')}</td>
              <td><s>90 USD</s></td>
              <td>110 USD</td>
            </tr>
            <tr>
              <td>{t('Estudiante universitario completo')}<br /><small>{t('(Incluye acceso a todos los días del congreso)')}</small></td>
              <td><s>60 USD</s></td>
              <td>90 USD</td>
            </tr>
            <tr>
            <td>{t('Estudiante universitario un día')}</td>
              <td><s>50 USD</s></td>
              <td>60 USD</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="custom-warning text-center">
        <strong>{t('Importante')}:</strong> {t('Bajo ninguna circunstancia las tarifas de inscripción son reembolsables.')}
      </p>
      <h4 className="important-dates-title text-center">
        {t('Fechas importantes a tomar en cuenta:')}
      </h4>
      <ul className="important-dates">
        <li><strong>{t('Inicio del registro:')}</strong> {t('Lunes 1 de julio de 2024')}</li>
        <li><strong><s>{t('Fin del registro Early Bird:')}</s></strong><s>{t('  Miércoles 31 de julio de 2024')}</s></li>
      </ul>
      <div className="button-group">
        <a href="https://forms.gle/n49RCnwSeV1Qofny6">
          <Button color="success" className="btn btn-primary my-3">{t('¡INICIAR REGISTRO!')}</Button>
        </a>
      </div>
      <p className="registration-help text-center">
        {t('Si tiene dudas acerca de las tarifas o del proceso de registro, por favor escriba un correo electrónico a ')} <a href="mailto:faq@cecspace.org">faq@cecspace.org</a>.
      </p>
    </div>
  );
};

export default RegistrationContent;
