import { SLUGS } from "./contstants/Contstants";
import { AboutPage } from "./pages/About/About.page";
import { CallForAbstracts } from "./pages/CallForAbstracts/CallForAbstracts.page";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { PreviousEditionsPage } from "./pages/PreviousEditions/PreviousEditions.page";
import { ProgramPage } from "./pages/Program/Program.page";
import { SpeakersPage } from "./pages/Speakers/Speakers.page";
import { RegistrationPage } from "./pages/Registration/Registration.page";
import { VenuePage } from "./pages/Venue/Venue.page";
import { VenueCityPage } from "./pages/VenueCity/VenueCity.page";
import { HotelsPage } from "./pages/Hotels/Hotels.page";
import { ScholarshipsPage } from "./pages/Scholarships/Scholarships.page";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy/PrivacyPolicy.page";

const routes = [
  { path: SLUGS.HOME, component: <LandingPage /> },
  { path: SLUGS.VENUE, component: <VenuePage /> },
  { path: SLUGS.VENUE_CITY, component: <VenueCityPage /> },
  { path: SLUGS.ABOUT, component: <AboutPage /> },
  { path: SLUGS.PREVIOUS_EDITIONS, component: <PreviousEditionsPage /> },
  { path: SLUGS.PROGRAM, component: <ProgramPage /> },
  { path: SLUGS.SPEAKERS, component: <SpeakersPage /> },
  { path: SLUGS.CALLFORABSTRACTS, component: <CallForAbstracts /> },
  { path: SLUGS.REGISTRATION, component: <RegistrationPage /> },
  { path: SLUGS.HOTELS, component: <HotelsPage /> },
  { path: SLUGS.SCHOLARSHIPS, component: <ScholarshipsPage /> },
  { path: SLUGS.PRIVACY_POLICY, component: <PrivacyPolicyPage /> },
];

export default routes;
